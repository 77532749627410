import navigationSlice, {loadHamburgerContent} from "../ReduxStore/navigationSlice";
import React from "react";

export default function HamburgerMenuItem({item}) {
  const {id, name, childrenCount, link} = item;
  const hasChildren = (childrenCount > 0);

  const handleClick = (e) => {
    if (hasChildren) {
      e.preventDefault();
      const dispatch = getReduxStore().dispatch
      dispatch(loadHamburgerContent({id}));
      dispatch(navigationSlice.actions.navigateToHamburgerPanel({id}));
    } else {
      return true;
    }
  }

  return <li className="py-1">
    <a className="!text-black block relative" href={link} onClick={handleClick}>
      {hasChildren && <span
        className="inline-block absolute t-0 right-2 fa fa-chevron-right"/>
      }

      {name}
    </a>
  </li>
}
