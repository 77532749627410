import React, {useCallback} from 'react';
import clsx from "clsx";
import navigationSlice, {handleMouseMenuEvent} from "../ReduxStore/navigationSlice";

const navActions = navigationSlice.actions;

/*
 * A clickable label in the menu bar. Not used for the HamburgerMenuButton,
 * which is its own thing.
 *
 */
export default function MenuBarItem({
  item, toggleMenuItem,
  dispatch,
  dropdownState={},
  rightMost=false,
  disabled=false,
  ...props
}) {

  dropdownState ||= {};

  let {
    title,
    className = "text-center ",
    innerClasses = "block px-3 border-l border-gray-400",
    labelClasses = "inline-block leading-full",
    sectionId: menuId,
    displayFlavour = 'text',
    icon,
  } = item;

  const { activeMenuId, mouseHolder } = dropdownState;

  let selected = mouseHolder && (mouseHolder === item.sectionId);

  if (rightMost) {
    className = "absolute right-0 top-0 px-3";
  }

  const handleHover = useCallback((evt) => {
    if (disabled) return;

    //evt?.preventDefault();
    dispatch(handleMouseMenuEvent({
      menuId: menuId, outbound: false
    }))
  }, [menuId]);

  const handleBlur = useCallback((evt) => {
    if (disabled) return;

    dispatch(handleMouseMenuEvent({
      menuId: menuId, outbound: true
    }))
  }, [menuId]);

  const linkClasses = [
    "text-sm font-bold pointer-cursor",
    selected ? "!text-brand-blue" : "!text-white"
  ]

  className = clsx(className,
    "inline-block text-center py-2 rounded-t",
    selected ? 'bg-white' : 'bg-brand-blue');

  labelClasses = clsx(labelClasses,
    selected ? ' border-b-4 border-brand-orange' : ' xxborder-b-2');

  return <li className={className}>
    <a className={clsx(linkClasses)}
      onMouseEnter={handleHover} onMouseLeave={handleBlur}>
      <div className={clsx(innerClasses)}>
        <label className={clsx(labelClasses)}>
          {title || 'Menu'}
          {icon && <span className={clsx(icon, "ml-2")}/>}
        </label>
      </div>
    </a>
  </li>
}

