import VehicleChooserApp from "../VehicleChooser/container/VehicleChooserApp";
import React from "react";

export default function PullDownSelector({selectorProps, handleClose}) {
  const selectorConfig = Object.assign({onComplete: 'reload'}, selectorProps);

  return <div className="absolute px-2 bg-white w-full text-left text-brand-blue">
    <div className="bg-white">
      <VehicleChooserApp config={selectorConfig}/>

      <div className="text-center py-2 mt-2 border-t border-gray-200">
        <a className="font-bold text-brand-blue cursor-pointer"
          onClick={handleClose}>Shop Without Vehicle</a></div>
    </div>
  </div>
}
