import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import HamburgerMenuPanel from "./HamburgerMenuPanel";

export default function HamburgerMenuInner({
  dispatch, rootItems,
  hamburgerState={},
  hamburgerContent={},
  menuWidth=360,
  ...props
}) {
  const [activePanelId, setActivePanelId] = useState(0);
  const { path } = hamburgerState;


  return <div className="h-full">
      {(path.length >= 0) && <HamburgerMenuPanel
        panelId={0}
        offset={path.length}
        items={rootItems}
      />}

      {path.map((id, n) => {
        let content = hamburgerContent[+id] || {};
        let offset = (n + 1) - path.length;

        return <HamburgerMenuPanel
          key={id}
          panelId={id}
          offset={offset}
          menuWidth={menuWidth}
          items={content?.children || []}
        />
      })}


  </div>
}
// TODO: HamburgerMenuPanel content=whatever;
// in HMP, build buckets if top level items are not buckets themselves
