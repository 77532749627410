import React, {useEffect, useState} from 'react';
import VehicleChooserApp from "../VehicleChooser/container/VehicleChooserApp";
import {useSkeletonLoading} from "../hooks/useSkeletonLoading";
import PullDownSelector from "./PulldownSelector";
import {useDispatch} from "react-redux";
import {navigationActions} from "../ReduxStore/navigationSlice";
import {getZIndex} from "../lib/zindex";

/**
 * Shown when no vehicle is selected.
 */
export default function NOLNoVehicleDisplay({
  themeName, disableSelector = false,
  selectorProps = {}
}) {
  const dispatch = useDispatch();

  const [panelOpen, setPanelOpen] = useState(false);
  const ref = useSkeletonLoading();

  useEffect(() => {
    if (panelOpen) {
      dispatch(navigationActions.updateNavigation({overlay: 'content'}))
    } else {
      dispatch(navigationActions.updateNavigation({overlay: ''}))
    }
  }, [panelOpen]);

  const togglePanel = () => {
    if (disableSelector) return;
    setPanelOpen(x => !x);
  }

  const styles = {backgroundColor: '#192b94'}

  const zIndex = getZIndex('showCustomerVehicle');

  if (panelOpen) {
    return <div ref={ref} className="relative" style={{zIndex}}>
      <a style={styles}
        className="block p-2 text-white hover:text-white cursor-pointer font-bold"
        onClick={togglePanel}>
        <div className="float-right"><span className="fas fa-times"/></div>
        <span className="fas fa-car-side"/>
        {' '}
        Add Vehicle
      </a>
      <PullDownSelector selectorProps={selectorProps} handleClose={togglePanel}/>
    </div>
  } else if (disableSelector) {
    return <div ref={ref} className="relative" style={{zIndex}}>
      <a style={styles}
        className="block p-2 text-white hover:text-white cursor-pointer font-bold">
        <span className="fas fa-car-side"/>
      </a>
    </div>
  } else {
    return <div ref={ref} className="relative" style={{zIndex}}>
      <a style={styles}
        className="block p-2 text-white hover:text-white cursor-pointer font-bold"
        onClick={togglePanel}>
        <div className="float-right">+</div>
        <span className="fas fa-car-side"/>
        {' '}
        Add New Vehicle
      </a>
    </div>
  }
}

