import React from 'react';

import AgentStatusMessage from './AgentStatusMessage';


export default function AgentStatusMain({...props}) {
  let { phone_call } = props;

  return <div className="agent-status-main bg-brand-blue">
    <AgentStatusCallDisplay {...props} />
    <AgentStatusMessage {...props} />
  </div>
}

function formatPhone(num) {
  num = (""+num).replace(/[^0-9]/g, '');  // digits only

  return [
    num.substring(0,3), num.substring(3,6), num.substring(6,10)
  ].join('.');
}

function AgentStatusCallDisplay(props) {
  let {phone_call, caller_id} = props;

  if (!phone_call)
    return <div className="agent-call">No phone call detected.</div>;

  //alert(caller_id);

  return <div className="agent-call">
    <span className="num">{formatPhone(phone_call.caller_id_number)} </span>
    {' • '}
    <span className="name">{phone_call.caller_id_name}</span>
  </div>;
}

