import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import ShoppingCartTop from '../components/above/ShoppingCartTop';
import ShoppingCartContents from '../components/cart_contents/ShoppingCartContents';
import CartSidebar from '../components/sidebar/CartSidebar';
import CheckoutStepsAdapter from "../components/above/CheckoutStepsAdapter";
import ViewCartReporter from "../components/analytics/ViewCartReporter";
import NewItemsReporter from "../components/analytics/NewItemsReporter";
import BraintreeTokenFetcher from "../../Checkout/braintree/BraintreeTokenFetcher";
import CatchErrors from "../../Utility/CatchErrors";
import {useSkeletonLoading} from "../../hooks/useSkeletonLoading";

const isDev = (process.env.NODE_ENV == 'development');

// 2023-06 made Braintree-aware; verified shoppingCart and all descendants are camelcased
export default function ShoppingCartUI(props) {
  const ref = useSkeletonLoading();
  const {shoppingCart, currentAgent, dispatch, braintreeData, emailUser} = props;

  if (!shoppingCart || !shoppingCart.cartCode) {
    return null;
  }

  return <div ref={ref} id="cart-app">
    <CatchErrors>
      <CheckoutStepsAdapter
        currentAgent={currentAgent}
        checkoutFlavour={shoppingCart.checkoutFlavour}/>
      <BraintreeTokenFetcher cartCode={shoppingCart.cartCode} dispatch={dispatch}/>
    </CatchErrors>

    <CatchErrors><ShoppingCartTop {...props} /></CatchErrors>

    <CatchErrors>
      <ViewCartReporter {...props} />
      <NewItemsReporter {...props} />
    </CatchErrors>

    <CatchErrors>
      <div className="row clear-me">
        <ShoppingCartContents {...props} />

        <CartSidebar {...props} />
      </div>
    </CatchErrors>

    <div className="clear-me"/>

  </div>
}

//

// PUT /cart/xxx.json _method=patch
// cart_code,
// shopping_cart { coupon_code: x}
// content-type: application/json;charset=UTF-8
//
// {saveCart.open &&
// <SaveCartContainer {...props} />}
