import React from 'react';
import clsx from 'clsx';
import MenuBarItem from "./MenuBarItem";
import CatchErrors from "../Utility/CatchErrors";
import HamburgerButtonApp from "../HamburgerMenu/HamburgerButtonApp";

// NOLA 2024 Menu Bar. Controls the DropdownMenu
// by sending updates to Redux.
export default function MenuBar({
  menuConfig = {}, menuItems = [],
  dispatch,
  dropdownState = {},
  hamburgerState= {},
  classNames, ...props
}) {
  classNames = clsx(classNames, menuConfig.classNames ||
    "bg-brand-blue text-white");

  return <div>
    <nav className={clsx(classNames)}>
      <CatchErrors>
        <ul className="container mx-auto block p-0 my-0 align-middle relative clearfix">
          <li className={clsx('inline-block mr-2 align-middle')}>
            <HamburgerButtonApp/>
          </li>
          {menuItems.map((item, index) => <MenuBarItem
            key={index}
            item={item}
            rightMost={index === menuItems.length - 1}
            dispatch={dispatch}
            dropdownState={dropdownState}
            disabled={hamburgerState?.open}
          />)}
        </ul>
      </CatchErrors>
    </nav>
  </div>
}

