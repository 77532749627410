import React, {useEffect} from 'react';

import {mergeNewChoice, setVehicleChoice} from "../../ReduxStore/VehicleChooser/vehicleChoicesSlice";

import {chooserOptionsSlice} from "../../ReduxStore/VehicleChooser/chooserOptionsSlice";
import vehicleSlice, {saveSessionVehicle} from "../../ReduxStore/CustomerVehicle/vehicleSlice";

import VehicleSelectorUi from "./VehicleSelectorUI";
import {CORE_LEVELS, VehicleSelectorConfigContext} from "../logic/chooserConfig";
import VehicleCompletionHandler from "./VehicleCompletionHandler";
import CatchErrors from "../../Utility/CatchErrors";

// Top component under VehicleChooserApp
// This looks to see if vehicle is already selected.
// If so, it returns CompletedVehicleChooserDisplay, otherwise returns the actual
// vehicle selector.

export default function VehicleChooserContainer({
  dispatch, config, options,
  choices, customerVehicle,
  productDetail, // lloyd
  vehicleContext,
  ...props
}) {

  // INITIAL MOUNT.
  // The component knows the category or group's min/max years, hopefully,
  // so set the year range used by the ItemChooser.
  useEffect(() => {
    dispatch(chooserOptionsSlice.actions.setYearRange({
      yearRange: config.yearRange
    }));
  }, [config]);

  // onChoiceMade(level-name, selected-value) - called when user
  // chooses year, make, fitment, etc.
  const onChoiceMade = ({name, value, description}) => {
    const {productContext, levels = CORE_LEVELS } = config;

    // We build the new choices hash before sending to redux, so that
    // an up-to-date copy is available here.
    choices = mergeNewChoice({name, value, choices, levels});
    // console.log(`onChoiceMade ${name} => ${value}`, choices);

    // inform redux's choices store of the user's choice (ie, make=Toyota)
    dispatch(setVehicleChoice({
      name,
      value,
      choices,
      levels,
      productContext  // for context
    }));

    if (_.includes(CORE_LEVELS, name)) {
      // Do we have the CORE THREE CHOICES? If so, save
      // vehicle to the back-end.
      if (choices.year && choices.make && choices.model) {
        dispatch(saveSessionVehicle({
          params: choices,
          productContext,
          vehicleContext: {source: 'user'}
        }));
      }
    } else if (customerVehicle) {
      // fitmentDescription or whatever
      let dkey = `${name}Description`; // 2024-03 changed from snake_case

      // Convey fitment and lloyd_unique selections to VehicleSlice.customerVehicle,
      // as the VehicleChangeWatcher in the products section looks for them there.
      customerVehicle = Object.assign({},
        customerVehicle, {[name]: value, [dkey]: description});

      dispatch(vehicleSlice.actions.setVehicle({customerVehicle}));
    }

    // 2022 side effects
    // hide-on-fitment-select is only usage, in products/show.html.haml
    const elements = document.getElementsByClassName(`hide-on-${name}-select`);
    if (elements) {
      for (let elem of elements) {
        elem.remove();
      }
    }

  }

  // previously productContext, vehicleContext

  return <VehicleSelectorConfigContext.Provider value={config}>
    <CatchErrors>
      <VehicleSelectorUi
        choices={choices}
        options={options}
        customerVehicle={customerVehicle}
        productDetail={productDetail}
        onChoiceMade={onChoiceMade}
      />
    </CatchErrors>
    <CatchErrors>
      <VehicleCompletionHandler
        config={config}
        customerVehicle={customerVehicle}
        vehicleContext={vehicleContext}
      />
    </CatchErrors>
  </VehicleSelectorConfigContext.Provider>

}


