export const ZINDEXES = {
  shadeOverlay: 1000,
  dropdownMenu: 2000,
  showCustomerVehicle: 2100, // pulldown vehicle selector at top of NOLA navbar
  hamburgerMenu: 200_000,

}

export function getZIndex(name, fallback = 0) {
  name = (''+name).trim();
  return ZINDEXES[name] || fallback;
}

