import React from 'react';
import VehicleChooserLevel from "./VehicleChooserLevel";
import FitmentNote from "./FitmentNote";

import {getEnabledChooserLevels, useVehicleSelectorConfig} from "../logic/chooserConfig";
import {useSkeletonLoading} from "../../hooks/useSkeletonLoading";
import clsx from "clsx";

export default function FullVehicleSelector(props) {
  const { options, onChoiceMade, } = props;
  const config = useVehicleSelectorConfig();

  const myRef = useSkeletonLoading();

  const levels = getEnabledChooserLevels({config, options});

  let classes =
    "vehicle-selector vehicle-selector-incomplete mt-4 vehicle-selector-" +
    (config.layout || 'stacked');

  return (
    <div ref={myRef} id="vehicle-selector" className={classes}>
      <ChooserHeadline config={config} headline={config.headline} />

      <div className={"selectors selectors-" + _.size(levels)}>
        {levels.map((level, index) =>
          <VehicleChooserLevel
            key={level}
            onChoiceMade={onChoiceMade}
            level={level}
            index={index}
            config={config}
            {...props}
          />)}
      </div>

      {_.includes(levels, 'fitment') && <FitmentNote config={config} />}
    </div>);
}

/**
 * With the unfortunate class name of .prompt,
 * this has styles in group_top.scss and maybe elsewhere.
 *
 * @param layout
 * @param headline
 * @returns {Element}
 * @constructor
 */

function ChooserHeadline({layout, config = {}, headline="What Do You Drive?"}) {
  // FIXME: legacy class 'prompt'
  const {headlineClasses = 'prompt', mainHeadlineClasses="", subhead, subheadClasses} = config;

  return <div className={clsx(headlineClasses)}>
    <div className={clsx(mainHeadlineClasses)}>
      {headline}
    </div>
    {subhead && <div className={clsx(subheadClasses)}>{subhead}</div>}
  </div>
}
