import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LocalUrlMaker from "../lib/LocalUrlMaker";

/**
 * NEW CartIcon for NOL 2024!
 *
 * see napaonline/header.html.haml for good class names.
 *
 * @param cartCode
 * @param cartUrl
 * @param itemCount
 * @param className
 * @param linkClasses
 * @param iconClasses
 * @param countClasses
 * @returns {Element}
 * @constructor
 */
export default function TailwindCartIcon({
  cartCode, cartUrl, itemCount,
  className = "text-xl",
  linkClasses = "inline-block relative",
  iconClasses = "fas fa-shopping-cart",
  countClasses = ""
}) {

  cartUrl ||= LocalUrlMaker.prefixedPath(`/cart/${cartCode}`)

  const realCount = (+itemCount);

  const title = (realCount > 0) ?
    `You have ${realCount} ${realCount > 1 ? "items": "item"} in your shopping cart.` :
    "You have not yet added any items to your cart.";

  return (
    <div className={className}>
      <a className={linkClasses} href={cartUrl} rel="nofollow" title={title}>
        <i className={iconClasses}/>
        {(realCount > 0) && <div className={countClasses}>{itemCount}</div>}
      </a>
    </div>
  );
}
