import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {getZIndex} from "../lib/zindex";
import clsx from "clsx";
import navigationSlice from "../ReduxStore/navigationSlice";

export default function ShadedOverlay({
  overlay = "",
  dispatch,
  ...props
}) {
  const showContentOverlay = (overlay === "content");
  const showFullOverlay = (overlay === "full");
  const overlayActive = showContentOverlay || showFullOverlay;

  const [clickedAt, setClickedAt] = useState(null);


  useEffect(() => {
    let html = document.querySelector('html');

    if (showContentOverlay || showFullOverlay) {
      // no, don't mess with scrollbar, it makes page jiggle
      //html.style.overflowY = 'hidden';
    } else {
      //html.style.overflowY = 'scroll';
    }
  }, [overlay]);

  // close the overlay by double-clicking on it
  function handleClick(evt) {
    let t = new Date().getTime();

    // prior click? prior click less than a second ago?
    if (clickedAt) {
      let diff = t - clickedAt;
      if (diff < 1000) {
        dispatch(navigationSlice.actions.updateNavigation({overlay: false}))
      }
    }

    setClickedAt(t);
  }

  if (!overlayActive)
    return null;

  let zIndex = getZIndex('shadeOverlay');
  let classes = [
    showFullOverlay ? 'fixed' : 'absolute',
    "block top-0 bottom-0 left-0 right-0 bg-black opacity-60"
  ]
  return <div className={clsx(classes)}
    onClick={handleClick}
    style={{zIndex: zIndex}}></div>

}
