import React from 'react';
import PropTypes from 'prop-types';
import ShowCustomerVehicleUI from "./ShowCustomerVehicleUI";
import NOLNoVehicleDisplay from "./NOLNoVehicleDisplay";

import {vehicleSlice} from "../ReduxStore/CustomerVehicle/vehicleSlice";

/**
 * 2022 - modernized to use vehicleSlice.
 *
 * Handle Redux interactions for ShowCustomerVehicleApp, which is
 * the top-of-screen widget that shows the currently selected
 * customer vehicle.
 *
 */
export default function ShowCustomerVehicleContainer({
  customerVehicle, icon, uplink, themeName, disableSelector = false, selectorProps={}}) {
  if (customerVehicle) {
    return <ShowCustomerVehicleUI
      themeName={themeName}
      icon={icon}
      customerVehicle={customerVehicle}
      uplink={uplink}
    />
  } else if (themeName === "napaonline") {
    return <NOLNoVehicleDisplay themeName={themeName}
      disableSelector={disableSelector}
      selectorProps={selectorProps} />
  } else {
    return <NoVehicleDisplay themeName={themeName} selectorProps={selectorProps} />
  }
}

function NoVehicleDisplay({}) {
  // LEGACY VERSION - semantic class names
  return <div className="stored-vehicle-inner"/>
}

ShowCustomerVehicleContainer.propTypes = {
  // from Rails view template: customer_vehicle&.serializable_hash.
  // Upon loading this will be used to initialize Redux, becoming customerVehicle
  // @example {"id":1602666,"year":2018,"vehicle_make_id":160,
  //   "vehicle_model_id":16026,"vehicle_make_name":"Chevrolet",
  //   "vehicle_model_name":"Corvette","vehicle_type":"Car",
  //   "short_make_name":"Chevy","short_model_name":"Corvette"}
  initialVehicle: PropTypes.object,

  // include an FontAwesome icon when a vehicle is present?
  // AAG theme has none, NOL theme has 'fas fa-garage-open'
  icon: PropTypes.string,

  // from Redux
  dispatch: PropTypes.func.isRequired,

  // optional - only when set - often null
  customerVehicle: PropTypes.shape({
    id: PropTypes.number
  }),
};
