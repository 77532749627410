import AgentStatusCart from './AgentStatusCart';
import AgentStatusMain from './AgentStatusMain';
import AgentStatusReloader from './AgentStatusReloader';
import AgentStatusCallerIcon from './AgentStatusCallerIcon';

export default class AgentStatusDisplay extends React.Component {
  render() {
    let {phone_call, agent, callbacks} = this.props;
    if (phone_call) {
      return this.renderWithCaller();
    } else {
      return this.renderWithoutCaller();
    }
  }

  renderWithCaller() {
    // update legacy orange toolbar
    $('#agent-toolbar').addClass('active-caller');

    return <div className="with-caller container mx-auto">
      <AgentStatusCallerIcon {...this.props} />
      <AgentStatusMain {...this.props} />
      <AgentStatusCart {...this.props} />
      <AgentStatusReloader {...this.props} />
    </div>
  }

  renderWithoutCaller() {
    // update legacy orange toolbar
    $('#agent-toolbar').removeClass('active-caller');

    return <div className="without-caller container mx-auto bg-grey-400">
      <AgentStatusDefaultIcon {...this.props} />
      <AgentStatusMain {...this.props} />
      <div className="agent-status-cart"><p> </p></div>
      <AgentStatusReloader {...this.props} />
    </div>

  }
}

// Default icon - shown when there is NO CALL, this
// just has a "person" icon and the agent's own extension number,
// which should hopefully validate their existence as a human being
// and provide some comfort in these bleak times.
function AgentStatusDefaultIcon(props) {
  let {agent = {}} = props;

  return <div className="agent-status-icon">
      <span className="fa fa-user-secret fa-lg"
        title={agent.username}/>
    <div className="subtitle">{agent.phone_extension || '???'}</div>
  </div>
}


