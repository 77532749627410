import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LocalUrlMaker from "../lib/LocalUrlMaker";
import ActiveMenuPanel from "./ActiveMenuPanel";
import {handleMouseMenuEvent, navigationActions} from "../ReduxStore/navigationSlice";

export default function DropdownMenu({
  dropdownState = {}, dropdownContent={}, dispatch, overlay, ...props
}) {
  const {activeMenuId, open = false} = dropdownState;

  let content = activeMenuId ? dropdownContent[activeMenuId] : null;

  const handleHover = (evt) => {
    dispatch(handleMouseMenuEvent({
      menuId: activeMenuId, outbound: false
    }))
  };

  const handleBlur =(evt) => {
    dispatch(handleMouseMenuEvent({
      menuId: activeMenuId, outbound: true
    }))
  };

  if (!open) return null;

  // This wrapper serves as an anchor for the absolutely-positioned content.
  return <div
    onMouseEnter={handleHover} onMouseLeave={handleBlur}
    className="relative bg-white overflow-visible w-full">
    { content && <ActiveMenuPanel
      html={content}
      dropdownState={dropdownState}
    />}
  </div>


}
