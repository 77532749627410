import React, {useEffect} from 'react';

import {performOnCompleteAction, reportVehicleSelected} from "../logic/onComplete";
import {camelizeKeys} from "../../ReduxStore/Helpers/transformObject";
import {useDispatch} from "react-redux";

export default function VehicleCompletionHandler({
  config, customerVehicle, vehicleContext}) {

  customerVehicle = camelizeKeys(customerVehicle);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("VehicleCompletionHandler triggered", customerVehicle);

    // if (_.isEmpty(customerVehicle))
    //   return;

    if (customerVehicle?.source == 'initial' || vehicleContext?.source == 'initial') {
      console.log("VehicleCompletionHandler: ignore initial vehicle");
      return;
    }

    // report to google etc.
    reportVehicleSelected(customerVehicle);

    if (customerVehicle?.id) {
      console.log(`have customer vehicle ${customerVehicle?.id || 0} - performing onCompleteAction`);
      performOnCompleteAction({dispatch, customerVehicle, config, vehicleContext});
    }
  }, [customerVehicle?.id, vehicleContext]);

  //return <div className="text-xl text-red-500">ON COMPLETE={config.onComplete}</div>
  return null;

  // return (
  //   <div className="debug">
  //     <h3>VehicleCompletionHandler</h3>
  //     <pre>CV={JSON.stringify(customerVehicle, null, 2)}</pre>
  //     <pre>VC={JSON.stringify(vehicleContext, null, 2)}</pre>
  //     <pre>{JSON.stringify(config, null, 2)}</pre>
  //
  //   </div>
  // );
}

