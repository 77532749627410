import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from "clsx";
import HamburgerMenuInner from "./HamburgerMenuInner";
import {getZIndex} from "../lib/zindex";

export default function HamburgerMenu({
  hamburgerState = {open: false},
  //rootItems = [],
  ...props
}) {
  const {open} = hamburgerState;

  const ref = useRef(null);
  const [styles, setStyles] = useState({height: 0, width: 0});
  const [menuWidth, setMenuWidth] = useState(0);

  const zIndex = getZIndex('hamburgerMenu');

  // Size the menu appropriately for the browser.
  useEffect(() => {
    let height = 0;
    let width = 0;

    if (ref?.current && open) {
      let elem = ref.current;
      let rect = elem.getBoundingClientRect(); // top, bottom, ...
      height = window.innerHeight - rect.top;
      width = window.innerWidth > 600 ? 360 : (0.98 * window.innerWidth);
    }

    setStyles({height: height, width: width, zIndex: zIndex});
    setMenuWidth(width);
  }, [ref?.current, open])

  const classes = [
    "absolute left-0 transition-width duration-1000 bg-white -top-24 lg:top-0",
    open ? "overflow-y-scroll" : ""
  ]
  //
  // return <div className="bg-red-400 container mx-auto relative"> HI BOB
  // </div>

  // The container is ALWAYS present, but has height/width zero when menu is not open.
  // return <div className="bg-red-400 container mx-auto relative">

  //return //<div className="w-auto bg-white overflow-hidden">
    return <div id="hamburger-menu" ref={ref}
      className={clsx(classes)}
      style={styles}>
      {open && <HamburgerMenuInner
        menuWidth={menuWidth}
        hamburgerState={hamburgerState}
        {...props}/>}
    </div>
  //</div>
}
