import React, {useState, useEffect} from 'react';
import {isEmpty, upperCase} from 'lodash';
import CatchErrors from "../Utility/CatchErrors";
import HamburgerMenuItem from "./HamburgerMenuItem";
import clsx from "clsx";

export default function HamburgerMenuSection({
  bucket, ...props
}) {
  const {name, headlineButtonCaption, headlineButtonUrl,
    headlineButtonClasses="bg-brand-blue !text-white rounded-sm px-1 text-xs font-bold",
    children = []} = bucket;

  if (isEmpty(children))
    return <div></div>

  return (
    <div className="bg-white my-4 px-4 relative">
      {headlineButtonCaption && <a className={clsx('absolute top-0 right-2', headlineButtonClasses)}
        href={headlineButtonUrl}>{headlineButtonCaption}</a>}

      <h3 className="text-gray-400">{upperCase(name)}</h3>
      <CatchErrors>
        <ul className="p-1">
          {
            children.map((item, index) => <HamburgerMenuItem
              key={index} item={item}/>)
          }
        </ul>
      </CatchErrors>
      <hr/>
    </div>
  );
}


