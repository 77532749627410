import React, {useState, useEffect} from 'react';
import {connect, Provider} from "react-redux";
import {getReduxStore} from "../ReduxStore/store";
import DropdownMenu from "./DropdownMenu";

function mapStateToProps(state) {
  const {navigationSlice = {}} = state;

  return navigationSlice;
}

let Wrapper = connect(mapStateToProps)(DropdownMenu);

export default function DropdownMenuApp(props) {
  return <Provider store={getReduxStore()}>
    <Wrapper {...props} />
  </Provider>
}
