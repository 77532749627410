import React, {useMemo, useState} from 'react';
import {camelizeKeysDeep} from "../ReduxStore/Helpers/transformObject";
import {describeVehicle} from "../VehicleChooser/logic/vehicle";
import {deleteSessionVehicle} from "../ReduxStore/CustomerVehicle/vehicleSlice";
import {useDispatch} from "react-redux";
import clsx from "clsx";
import {usStreet} from "smartystreets-javascript-sdk";
import {useSkeletonLoading} from "../hooks/useSkeletonLoading";
import {getZIndex} from "../lib/zindex";

/**
 * Displayed in the Top Nav bar when a vehicle is selected,
 * allowing for the vehicle to be reset.
 */
export default function ShowCustomerVehicleUI({
  uplink, customerVehicle, icon = "", classNames = "", themeName, disableSelector = false
}) {
  const dispatch = useDispatch();
  const [panelOpen, setPanelOpen] = useState(false);
  const ref = useSkeletonLoading();

  const zIndex = getZIndex('showCustomerVehicle');

  // FIXME: camelize when putting into redux
  customerVehicle = camelizeKeysDeep(customerVehicle);

  const {vehicleUrl, source} = customerVehicle;
  const caption = describeVehicle(customerVehicle);

  const handleDelete = (evt) => {
    evt.preventDefault();

    console.log(`ShowCustomerVehicle: delete; uplink=${uplink}`);

    // inform back-end that it should be cleared from session;
    // this will also clear from Redux, after the operation completes.
    dispatch(deleteSessionVehicle({
      by: 'ShowCustomerVehicleUI.handleDelete', uplink: uplink
    }));

    // clear from Redux
    // dispatch(vehicleSlice.actions.clearVehicle({by: 'ShowCustomerVehicleUI.handleDelete'}));
  }

  if (themeName === "napaonline") {
    const styles = {backgroundColor: '#192b94', zIndex}; //FIXME PRONTO
    return <div ref={ref} className="relative" style={styles}>
      <a className="!text-brand-orange font-bold p-2 block" onClick={() => setPanelOpen(!panelOpen)}>
        <div className="float-right"><i className={panelOpen ? "fa fa-times !text-white" : "fa fa-chevron-right" }/></div>
        <span className="fas fa-car-side"/>
        {' '}
        {caption}
      </a>
      {panelOpen && <div className="absolute bg-white w-full text-center text-brand-blue py-8 ">
        <a className="!text-brand-blue font-bold" onClick={handleDelete}>
          Shop Without Vehicle
        </a>
      </div>}
    </div>
  }

  // LEGACY VERSION - AAG/NOL, with semantic class names
  classNames = clsx(classNames, 'stored-vehicle-inner',
    `${customerVehicle.source || 'vehicle'}-selected`);

// 2022-03 link to vehicle page if it exists
  return (
    <div ref={ref} className={classNames}>
      {icon && <i className={icon}/>}
      <strong>
        {vehicleUrl ? <a href={vehicleUrl}>{caption}</a> : caption}
      </strong>
      {' '}
      <a className="nobr click-me" id="clear-vehicle-top"
        onClick={handleDelete}>change vehicle</a>
    </div>)
}

