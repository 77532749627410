import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import LocalUrlMaker from "../lib/LocalUrlMaker";
import {getZIndex} from "../lib/zindex";

// TODO: use logic in ActivateComponentTags to scan for react components in these tube sections!

export default function ActiveMenuPanel({
  dropdownState = {},
  html="",
  ...props
}) {
  const innerRef = useRef(null);

  useEffect(() => {
    if (innerRef?.current && html) {
      innerRef.current.innerHTML = html;
    }
  }, [innerRef, html]);

  let zIndex = getZIndex("dropdownMenu");

  return <div className="absolute bg-white w-full" style={{zIndex: zIndex}}>
    <div className="container mx-auto bg-white text-black min-h-16">
      <div ref={innerRef}></div>
    </div>
  </div>
}
