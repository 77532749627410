import clsx from "clsx";
import React from "react";
import navigationSlice, {navigationActions} from "../ReduxStore/navigationSlice";

export default function HamburgerButton({
  className = 'block cursor-pointer leading-none w-10 text-center pt-2',
  iconClasses = "fas fa-bars text-lg",
  captionClasses = "text-tiny m-0 pb-1 p-0",
  caption = 'MENU',
  hamburgerState = {},
  dispatch,
  showCloseButton = false,
  ...props
}) {
  const {open} = hamburgerState;

  const handleClick = (e) =>  {
    let opening = !open;

    dispatch(navigationActions.updateNavigation({
      hamburgerState: Object.assign({}, hamburgerState, {open: opening}),
      // close the dropdown menu...
      dropdownState: { open: false },
      // and show overlay
      overlay: opening ? 'content' : ''
    }))
  }

  // invert colours when open
  className = clsx(className,
    open ? '!text-brand-blue bg-white' : '!text-white bg-brand-blue');

  if (showCloseButton && open) {
    iconClasses = 'fas fa-times text-2xl'
    return <a className={className} onClick={handleClick}>
      <div><i className={clsx(iconClasses)}/></div>
      <div className={clsx(captionClasses)}>&nbsp;</div>
    </a>
  }

  // menu closed - render hamburger button and "MENU" caption
  return (
    <a className={className} onClick={handleClick}>
      <div><i className={clsx(iconClasses)}/></div>
      {caption && <div className={clsx(captionClasses)}>{caption}</div>}
    </a>
  );

}
